<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                类型：
                <el-select size="small" v-model="auditType" placeholder="请选择" class="select_container input" clearable>
                    <el-option
                            v-for="item in auditList"
                            :key="item.index"
                            :label="item.label"
                            :value="item.type">
                    </el-option>
                </el-select>
                状态：
                <el-select size="small" v-model="statusId" placeholder="请选择" class="select_container input"
                           @change="onStatus()" clearable>
                    <el-option
                            v-for="item in statusList"
                            :key="item.index"
                            :label="item.label"
                            :value="item.status">
                    </el-option>
                </el-select>
                <!--                修改时间：-->
                <!--                <el-date-picker-->
                <!--                        v-model="date"-->
                <!--                        size="small"-->
                <!--                        value-format="timestamp"-->
                <!--                        type="daterange"-->
                <!--                        range-separator="-"-->
                <!--                        start-placeholder="开始日期"-->
                <!--                        end-placeholder="结束日期"-->
                <!--                        :default-time="['00:00:00', '23:59:59']"-->
                <!--                        @change="dateChange" class="select_container">-->
                <!--                </el-date-picker>-->
                <el-button size="small" type="primary" class="add_button select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="add_button select_container" @click="onSearch">查询
                </el-button>
            </div>
            <el-table border :data="this.$store.state.user.audit.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="type" label="类型" align="center" min-width="100px">
                    <template slot-scope="scope">
                        {{auditList[scope.row.type-1].label}}
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="修改人" align="center" min-width="100px"></el-table-column>
                <el-table-column label="提交时间" align="center" min-width="160px">
                    <template slot-scope="scope">
                        {{createTimeHandler(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="审核状态" align="center" min-width="100px">
                    <template slot-scope="scope">
                        {{scope.row.status===1?'已审核':'未审核'}}
                    </template>
                </el-table-column>
                <el-table-column label="通过状态" align="center" min-width="100px">
                    <template slot-scope="scope">
                        {{scope.row.pass===1?'已通过':scope.row.pass===2?'已拒绝':'未审核'}}
                    </template>
                </el-table-column>
                <el-table-column prop="auditTime" label="审核时间" align="center" min-width="160px">
                    <template slot-scope="scope">
                        {{createTimeHandler(scope.row.auditTime)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-message-solid"
                                 @click="auditHandler(scope.row)" v-if="scope.row.status===2">审核
                        </el-link>
                        <el-link type="primary" icon="el-icon-view"
                                 @click="seeHandler(scope.row)" v-if="scope.row.status===1">查看
                        </el-link>

                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.user.audit.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import {setData, getData} from "@/utils/cookies";
    import axios from '@/libs/axios'
    import {getDateFromTimeStamp} from '@/libs/tools'

    export default {
        name: 'List',
        data() {
            return {
                date: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()],
                startTime: '',
                endTime: '',
                auditList: [
                    {type: 1, label: '上传Excel'}, {type: 2, label: '添加教材'},
                    {type: 3, label: '删除教材'}, {type: 4, label: '更新教材'},
                    {type: 5, label: '添加单元'}, {type: 6, label: '删除单元'},
                    {type: 7, label: '更新单元'}, {type: 8, label: '添加章节'},
                    {type: 9, label: '删除章节'}, {type: 10, label: '更新章节'},
                    {type: 11, label: '添加字词'}, {type: 12, label: '删除字词'},
                    {type: 13, label: '更新字词'}
                ],
                auditType: '',//类型
                statusId: 2,//状态id
                statusList: [{status: 1, label: '已审核'}, {status: 2, label: '未审核'}],
                pagination: {
                    page: 0,
                    size: 10,
                }
            }
        },
        mounted() {
            this.statusId = getData("statusId");
            this.getAuditList();
        },
        methods: {
            onStatus() {
                setData("statusId", this.statusId)
            },
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },


            // viewHandler(row) {
            //     setData('user', row);
            //     // this.$router.push('/user/small/see');
            // },
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getAuditList();
            },
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getAuditList();
            },
            onSearch() {
                this.pagination.page=0
                this.getAuditList();
            },
            //获取审核列表
            getAuditList() {
                this.$store.dispatch("user/auditList", {
                    'type': this.auditType,
                    'page': this.pagination.page,
                    'size': this.pagination.size,
                    'status': this.statusId
                });
            },
            //重置
            onReset() {
                this.auditType = '';
                this.statusId = '';
                this.getAuditList()
            },

            // 删除教材
            deleteHandler(row) {
                this.$store.dispatch("school/deleteSchool", {'schoolId': row.schoolId}).then((data) => {
                    if (this.$store.state.user.user.list.length === 1) {
                        this.pagination.page--
                    }
                    this.getSchoolList();
                });
            },
            dateChange(date) {
                this.startTime = date[0];
                this.endTime = date[1];
            },
            //时间戳转换工具
            createTimeHandler(timeStamp) {
                return getDateFromTimeStamp(timeStamp)
            },
            //审核
            auditHandler(row) {
                if (row.type === 2 || row.type === 3 || row.type === 4) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/detail');
                } else if (row.type === 5 || row.type === 6 || row.type === 7) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/unitDetail');
                } else if (row.type === 8 || row.type === 9 || row.type === 10) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/chapterDetail');
                } else if (row.type === 11 || row.type === 12 || row.type === 13) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/wordDetail');
                } else {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/excelDetail');
                }
            },
            //查看审核
            seeHandler(row) {
                if (row.type === 2 || row.type === 3 || row.type === 4) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/see');
                } else if (row.type === 5 || row.type === 6 || row.type === 7) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/unitSee');
                } else if (row.type === 8 || row.type === 9 || row.type === 10) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/chapterSee');
                } else if (row.type === 11 || row.type === 12 || row.type === 13) {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/wordSee');
                } else {
                    setData('auditInfo', row)
                    this.$router.push('/manage/audit/excelSee');
                }
            },

        }
    }
</script>

<style scoped lang="scss">
    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;
    }

    .school_input {
        display: inline-block;
        width: 300px;
    }

    .input {
        width: 150px;
    }

    .select_container {
        margin-right: 30px;
        margin-bottom: 30px;
    }

    .upload_container {
        display: inline-flex;
        width: 80px;
        height: 32px;
        position: relative;
        overflow: hidden;
        background-color: #409EFF;
        border-radius: 5px;
        color: #ffffff;
        line-height: 32px;
        justify-content: center;
        margin-right: 30px;
        font-size: 12px;
    }

    .upload_input {
        display: inline-block;
        position: absolute;
        top: 0;
        left: -500px;
        width: 580px;
        height: 32px;
        border: none;
        outline: none;
    }

    .edit-container {

        text-align: center;

        .title {
            margin-bottom: 50px;
        }

        .row-container {
            padding: 8px 0;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            /*上传图片框修饰*/
            .upload_container {
                width: 300px;
                display: inline-block;
                text-align: start;
            }

            .avatar-uploader, .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                display: inline-block;
                height: 130px;
                width: 130px;
            }

            .el-upload {
                border: 1px dashed #d9d9d9;
            }

            .avatar-uploader, .el-upload:hover {

            }

            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 130px;
                height: 130px;
                line-height: 130px;
                text-align: center;
            }

            .avatar {
                width: 130px;
                height: 130px;
                display: block;
            }

            .url_image {
                height: 100%;
                margin-right: 10px;
                display: flex;
                justify-content: center;
            }

            .right-align {
                display: inline-block;
                text-align: right;
                width: 110px;
                height: 30px;
                line-height: 30px;
            }

            .red {
                color: red;
                padding: 5px;
            }

            .text {
                padding-left: 25px;
            }

            .input {
                display: inline-block;
                width: 300px;
                padding-left: 10px;
            }

            .textarea_container {
                height: 50px;
            }

            .textarea_text {
                height: 50px;
                line-height: 50px;
            }

            .switch_class {
                text-align: left;
            }

            .state_class {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                vertical-align: bottom;
                margin-left: 8px;
            }
        }

        .password-tip {
            margin-left: 185px;
            padding: 5px;
            width: 315px;
            text-align: left;
            color: #aaaaaa;
            display: block;
        }

        .action-container {
            margin-top: 30px;
            margin-left: 130px;
        }

        .save {
            padding: 12px 28px;
        }

        .cancel {
            margin-left: 20px;
            padding: 12px 28px;
        }

    }


</style>
